body {
  font-family: 'auto' !important;
}

body .content {
  margin-top: 150px;
  margin-bottom: 150px;
  padding: 25px !important;
}

#navbar {
  background-color: #434173;
}

body i,
body p {
  font-size: 18px;
}

body h2 {
  font-size: 37px !important;
  letter-spacing: 4px;
  margin-bottom: 30px !important;
}

body h2,
body h3 {
  font-weight: 600 !important;
  color: rgb(207, 148, 71);
}

body h1 {
  color: rgb(207, 148, 71) !important;
  font-size: 50px !important;
}

body h2 {
  color: rgb(207, 148, 71);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: rgb(207, 148, 71) !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: rgb(207, 148, 71) !important;
}

#navbar a {
  color: white !important;
  text-decoration: none !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  padding: 10px;
}

#navbar a:hover {
  color: rgb(207, 148, 71) !important;
}

body .form-label {
  font-weight: 600;
}

body .btn-primary {
  background-color: #28262E !important;
  border-color: #434173 !important;
  font-size: 18px !important;
  color: white !important;
  font-weight: 700;
}

body .modal-dialog .modal-header {
  border-color: transparent;
}

body .modal-dialog .modal-header .modal-title {
  text-align: center;
  width: 100%;
  color: rgb(207, 148, 71);
  font-weight: 600;
  font-size: 35px;
}

body .modal-dialog .modal-header button {
  background-color: white;
}

body .modal-dialog .modal-body {
  text-align: center;
}

body .modal-dialog .modal-content {
  background-color: #D8EEFF;
  border-color: #D8EEFF;
}

body table td {
  border-bottom-color: transparent;
}

body table td p {
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  body h1 {
    font-size: 37px !important;
  }

  body h2 {
    font-size: 33px !important;
  }

  body .seal {
    height: 100px !important;
  }
}